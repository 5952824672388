// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

export function state() {
    return {
        count: 0,
    }
};

export const getters = {
    totalCount: state => {
        return state.count;
    },
}

export const actions = {
    addToCart: function ({ commit }, payload) {
        commit(ADD_TO_CART, payload);
    },
    removeFromCart: function ({ commit }, payload) {
        commit(REMOVE_FROM_CART, 1);
    },
    clearCart: function ({ commit }, payload) {
        commit(CLEAR_CART, payload);
    }
}

export const mutations = {
    [ADD_TO_CART](state, payload) {
        state.count += payload;
    },
    [REMOVE_FROM_CART](state, payload) {
        if (state.count == 0) return
        state.count -= payload;
    },
    [CLEAR_CART](state, payload) {
        state.count = 0;
    }
}