// Cart
export const SET_DIVIDER_OP = 'SET_DIVIDER_OP';
export const SET_SPACER_OP = 'SET_SPACER_OP';
export const SET_QUESTION_OP = 'SET_QUESTION_OP';
export const SET_CONTAINER_OP = 'SET_CONTAINER_OP';
export const SET_DESCRIPTION_OP = 'SET_DESCRIPTION_OP';

import * as dfTypes from '~/utilities/dynamic-forms';

export function state() {
    return {
        dividerOp: JSON.parse(JSON.stringify(dfTypes.dividerOp)),
        containerOp: JSON.parse(JSON.stringify(dfTypes.containerOp)),
        spacerOp: JSON.parse(JSON.stringify(dfTypes.spacerOp)),
        descriptionOp: JSON.parse(JSON.stringify(dfTypes.descriptionOp)),
        questionOp: JSON.parse(JSON.stringify(dfTypes.questionOp)),
    }
};

export const getters = {
    dividerOp: state => {
        return state.dividerOp;
    },
    containerOp: state => {
        return state.containerOp;
    },
    spacerOp: state => {
        return state.spacerOp;
    },
    descriptionOp: state => {
        return state.descriptionOp;
    },
    questionOp: state => {
        return state.questionOp;
    },
}

export const actions = {
    setDividerOp: function({ commit }, payload) {
        commit(SET_DIVIDER_OP, payload);
    },
    setSpacerOp: function({ commit }, payload) {
        commit(SET_SPACER_OP, payload);
    },
    setQuestionOp: function({ commit }, payload) {
        commit(SET_QUESTION_OP, payload);
    },
    setContainerOp: function({ commit }, payload) {
        commit(SET_CONTAINER_OP, payload);
    },
    setDescriptionOp: function({ commit }, payload) {
        commit(SET_DESCRIPTION_OP, payload);
    },
}

export const mutations = {
    [SET_DIVIDER_OP](state, payload) {
        state.dividerOp = payload;
    },
    [SET_SPACER_OP](state, payload) {
        state.spacerOp = payload;
    },
    [SET_QUESTION_OP](state, payload) {
        state.questionOp = payload;
    },
    [SET_CONTAINER_OP](state, payload) {
        state.containerOp = payload;
    },
    [SET_DESCRIPTION_OP](state, payload) {
        state.descriptionOp = payload;
    },
}