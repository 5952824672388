//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const defaultOption = {
    loop: false,
    scrollbar: {
        draggable: false
    },
    spaceBetween: 20,
    slidesPerView: 4,
    watchSlidesVisibility: true,
    clickable: true
};
import * as ptypes from '~/utilities/props-types';
export default {
    props: {
        options: ptypes.OBJECT,
        useFasIcon: ptypes.fBOOLEAN
    },
    data: function() {
        return {
            sliderOption: Object.assign({}, defaultOption, this.options),
            isNavDisabled: false,
            isDotDisabled: false
        };
    },
    mounted: function() {
        let self = this;
        // self.$refs.pvCarousel.swiper.emit();
        this.$nextTick(() => {
            if (self.$refs.pvCarousel) {
                self.$refs.pvCarousel.swiper.emit('update');
                self.isNavDisabled =
                    self.$refs.pvCarousel.querySelectorAll(
                        '.swiper-button-disabled'
                    ) &&
                    self.$refs.pvCarousel.querySelectorAll(
                        '.swiper-button-disabled'
                    ).length > 1
                        ? true
                        : false;
                self.isDotDisabled =
                    self.$refs.pvCarousel.querySelectorAll(
                        '.swiper-pagination-bullet'
                    ) &&
                    self.$refs.pvCarousel.querySelectorAll(
                        '.swiper-pagination-bullet'
                    ).length < 2
                        ? true
                        : false;

                if (
                    self.options.navigation &&
                    document
                        .querySelector(self.options.navigation.nextEl)
                        .parentNode.querySelectorAll('.swiper-button-disabled')
                        .length > 1
                ) {
                    document
                        .querySelector(self.options.navigation.nextEl)
                        .parentNode.classList.add('disabled');
                }
            }
        });
    },
    methods: {
        resizeHandler: function() {
            this.isNavDisabled =
                this.$refs.pvCarousel.querySelectorAll(
                    '.swiper-button-disabled'
                ) &&
                this.$refs.pvCarousel.querySelectorAll(
                    '.swiper-button-disabled'
                ).length > 1;
            this.isDotDisabled =
                this.$refs.pvCarousel.querySelectorAll(
                    '.swiper-pagination-bullet'
                ) &&
                this.$refs.pvCarousel.querySelectorAll(
                    '.swiper-pagination-bullet'
                ).length < 2;
        }
    }
};
