//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data: function() {
        return {
            uri: window.location.pathname,
            k: 0
        };
    },
    async mounted() {
        await this.$checkSession();
        this.k++;
    },
    watch: {
        $route: async function() {
            this.uri = window.location.pathname;
            if (this.$isSeeker) {
                await this.$checkSessionMiddleware(this.uri);
                this.k++;
            }
        }
    },
    props: {
        chatCount: 0,
        wishlistCount: 0,
        jobAlertCount: 0,
        notificationCount: 0
    }
};
