import { errorModal, warningModal, infoModal, showInfoPopup, getInstitutionCategoryId_UI, extrabaseLogic, getInstitutionCategoryUrl_UI, isEmployer, isSeeker, ckconfig, youtube_parser, select2open, isJsonString, storeLocalLoginInfo, currencyCode, prepareCounters, getInstitutionCategoryId_Session, getInstitutionCategoryUrl_Session } from "~/utilities/helpers";
import { getDateFormate, getWidthClass, types, qTypes, ultimateIds, qWithOptions } from "~/utilities/dynamic-forms";
import { canAccess, canAccessProduct, SUBSCRIPTION, ADVERT, SUBFEATURES, JOBFEATURES, PLANSIDS_H, PLANSIDS_F, PLANSIDS_S, BANNERTYPEID_H, BANNERTYPEID_F, BANNERTYPEID_S } from "~/utilities/products";
export default (context, inject) => {
    inject('getInstitutionCategoryId_UI', getInstitutionCategoryId_UI);
    inject('extrabaseLogic', extrabaseLogic);
    inject('getInstitutionCategoryUrl_UI', getInstitutionCategoryUrl_UI);
    inject('isEmployer', isEmployer);
    inject('isSeeker', isSeeker);
    inject('ckconfig', ckconfig);
    inject('youtube_parser', youtube_parser);
    inject('select2open', select2open);
    inject('isJsonString', isJsonString);
    inject('errorModal', errorModal);
    inject('warningModal', warningModal);
    inject('infoModal', infoModal);
    inject('showInfoPopup', showInfoPopup);
    inject('storeLocalLoginInfo', storeLocalLoginInfo);
    inject('currencyCode', currencyCode);
    inject('prepareCounters', prepareCounters);
    inject('getInstitutionCategoryId_Session', getInstitutionCategoryId_Session);
    inject('getInstitutionCategoryUrl_Session', getInstitutionCategoryUrl_Session);

    inject('getDateFormate', getDateFormate);
    inject('getWidthClass', getWidthClass);
    inject('dfTypes', types);
    inject('qTypes', qTypes);
    inject('ultimateIds', ultimateIds);
    inject('qWithOptions', qWithOptions)


    inject('canAccess', canAccess);
    inject('canAccessProduct', canAccessProduct);
    inject('SUBSCRIPTION', SUBSCRIPTION);
    inject('ADVERT', ADVERT);
    inject('SUBFEATURES', SUBFEATURES);
    inject('JOBFEATURES', JOBFEATURES);
    inject('PLANSIDS_H', PLANSIDS_H);
    inject('PLANSIDS_S', PLANSIDS_S);
    inject('PLANSIDS_F', PLANSIDS_F);
    inject('BANNERTYPEID_H', BANNERTYPEID_H);
    inject('BANNERTYPEID_F', BANNERTYPEID_F);
    inject('BANNERTYPEID_S', BANNERTYPEID_S);
}