//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
    data: function() {
        return {
            uri: window.location.pathname,
            fullName: localStorage.getItem('fullName'),
            k: 0
        };
    },
    props: {
        chatCount: 0,
        notificationCount: 0
    },
    async mounted() {
        await this.$checkSession();
        this.k++;
    },
    watch: {
        $route: async function() {
            this.uri = window.location.pathname;
            if (this.$isEmployer) {
                await this.$checkSessionMiddleware(this.uri);
                this.k++;
            }
        }
    },
    computed: {
        ...mapGetters('cart', ['totalCount'])
    },
    methods: {
        ...mapActions('cart', ['addToCart'])
    },
    created() {
        const basket = JSON.parse(localStorage.getItem('basket'));
        if (basket) this.addToCart(Object.keys(basket).length);
    }
};
