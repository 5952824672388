export default function({ store, redirect, route }) {
    if ([
            '/employer-portal/form-templates',
            '/employer-portal/manage-job-advert/job-adverts',
            '/employer-portal/manage-job-advert/banner-adverts',
            '/employer-portal/performance/job-adverts',
            '/employer-portal/performance/banner-adverts',
            '/employer-portal/applications/tracking-board',
            '/employer-portal/applications/dashboard',
            '/employer-portal/checkout/basket',
            '/employer-portal/notifications',
            '/employer-portal/messaging-center',
            '/employer-portal/account',
            '/employer-portal/info',
            '/employer-portal/profile',
            '/employer-portal/purchase'
        ].includes(route.path)) {
        if (!store.$isEmployer())
            return redirect('/auth/employer')
    } else
    if ([
            '/job-seeker/apply',
            '/job-seeker/notifications',
            '/job-seeker/saved-jobs',
            '/job-seeker/messaging-center',
            '/job-seeker/dashboard',
            '/job-seeker/applications',
            '/job-seeker/profile',
            '/job-seeker/job-alerts',


            '/higher/job-seeker/apply',
            '/higher/job-seeker/notifications',
            '/higher/job-seeker/saved-jobs',
            '/higher/job-seeker/messaging-center',
            '/higher/job-seeker/dashboard',
            '/higher/job-seeker/applications',
            '/higher/job-seeker/profile',
            '/higher/job-seeker/job-alerts',

            '/further/job-seeker/apply',
            '/further/job-seeker/notifications',
            '/further/job-seeker/saved-jobs',
            '/further/job-seeker/messaging-center',
            '/further/job-seeker/dashboard',
            '/further/job-seeker/applications',
            '/further/job-seeker/profile',
            '/further/job-seeker/job-alerts',


            '/school/job-seeker/apply',
            '/school/job-seeker/notifications',
            '/school/job-seeker/saved-jobs',
            '/school/job-seeker/messaging-center',
            '/school/job-seeker/dashboard',
            '/school/job-seeker/applications',
            '/school/job-seeker/profile',
            '/school/job-seeker/job-alerts',
        ].includes(route.path)) {
        if (!store.$isSeeker())
            return redirect('/auth/job-seeker')
    }
}