export const OBJECT = {
    type: Object,
    default () {
        return {};
    }
}
export const nOBJECT = {
    type: Object,
    default: null
}

export const ARRAY = {
    type: Array,
    default () {
        return [];
    }
}

export const tBOOLEAN = {
    type: Boolean,
    default: true
}
export const fBOOLEAN = {
    type: Boolean,
    default: false
}

export const STRING = {
    type: String,
    default: ''
}

export const nSTRING = {
    type: String,
    default: null
}
export const vSTRING = (v) => {
    return {
        type: String,
        default: v
    }
}

export const vNUMBER = (v = null) => {
    return {
        type: Number,
        default: v
    }
}