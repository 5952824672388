//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Counters from '~/components/common/layout/Counters';
import CookieLaw from 'vue-cookie-law';
export default {
    components: {
        Counters,
        CookieLaw
    },
    data: function() {
        return {
            data: null
        };
    },
    mounted() {
        this.getFooter();
    },
    methods: {
        async getFooter() {
            const R = await this.$GetReq('sections/footer', {});
            this.data = R && R.success && R.data ? R.data : null;
        },
        async test() {
            const R = await this.$GetReq(
                'account/' +
                    (this.$isSeeker() ? 'job-seeker' : 'employer') +
                    '/expire-my-session',
                { Seconds: 1 }
            );
        }
    }
};
