export const SUBSCRIPTION = {
    CustomisedEmployerProfile: 200,
    PhotosGallerySlider: 201,
    KeyContactInformation: 202,
    MapLocation: 203,
    KeyInformationCards: 204,
    VideoUrlLink: 205,
    AdvertGoesLiveInHours: 206,
    FullControlOfJobAdverts: 207,
    AdvertsPerformanceMonitor: 208,
    CustomisedApplicationFormTemplates: 209,
    NotificationsMessagingCenter: 210,
    ApplicationTrackingBoard: 211,
    ApplicationDashboard: 212,
    NumberApplicationFormTemplates: 213,
    SpotlightClassification: 214,
    ProfileCover: 215,
    InstitutionalProfile: 216,
    SocialMediaLinks: 217,
    FindEmployerProfileVisibility: 218,
    MultiUserAccessManagement: 219,
}

export const ADVERT = {
    TextAdvertDetailHeaderBanner: 4,
    AllowVideoLink: 9,
    DocumentsUpload: 14,
}


export const BANNERTYPEID_H = {
    PREMIUM: "62c070de-2262-4b0f-a67f-08d9ddd511eb",
    TARGET: "9032da8e-e418-4ebd-74cb-08da6a3cce41",
    FEATIURED: "76047ad9-5a54-447c-132b-08da0f66ed76",
    STANDARD: "8baf618e-e7be-4917-74c9-08da6a3cce41",
    ACCESS: "8b4fbb9d-d203-4cf9-74ca-08da6a3cce41"
};
export const BANNERTYPEID_F = {
    PREMIUM: "46df2f16-e999-44b1-74cc-08da6a3cce41",
    TARGET: "73293129-c31e-41b1-74d0-08da6a3cce41",
    FEATIURED: "a2d0344e-1fca-4ef8-74cd-08da6a3cce41",
    STANDARD: "9f68eeb7-7fe8-4586-74ce-08da6a3cce41",
    ACCESS: "fc94e9bc-0b66-4e0b-74cf-08da6a3cce41"
};
export const BANNERTYPEID_S = {
    PREMIUM: "366bbe88-893b-4dc6-74d1-08da6a3cce41",
    TARGET: "dc848b14-298a-4ac9-74d5-08da6a3cce41",
    FEATIURED: "f9f86995-2938-433b-74d2-08da6a3cce41",
    STANDARD: "7e336539-ac3a-4d34-74d3-08da6a3cce41",
    ACCESS: "f236b16a-4127-49d4-74d4-08da6a3cce41"
};

export const PLANSIDS_H = {
    'dc1bfe63-f4bd-4f95-a680-08d9ddd511eb': 0,
    'b2d972c8-1e20-4537-688d-08d9de9c3f21': 1,
    'e7b83ebf-7c1a-4855-1f07-08d9dea84c9a': 2,
    'a11fc3f1-b9a7-435f-f3f7-08d9e352ff71': 3,
    '93b666d9-d517-47c5-f3f8-08d9e352ff71': 4
};
export const PLANSIDS_F = {
    '28bed7df-84d6-4241-74d6-08da6a3cce41': 0,
    '4fa38977-26a4-4d4b-74d7-08da6a3cce41': 1,
    '57ddbf3d-e8eb-41e2-74d8-08da6a3cce41': 2,
    '3bae9fcf-e10f-40b1-74d9-08da6a3cce41': 3,
    '59a9d594-cefe-4314-74da-08da6a3cce41': 4
};
export const PLANSIDS_S = {
    'f60e7b97-2fb3-4b1c-74db-08da6a3cce41': 0,
    '433a8839-79d2-4b8d-74dc-08da6a3cce41': 1,
    'e7a34024-6a9e-420f-74dd-08da6a3cce41': 2,
    '1e1cee31-8150-4a38-74de-08da6a3cce41': 3,
    'fe0ba11d-eea6-42ac-74df-08da6a3cce41': 4
};
export const JOBFEATURES = {
    0: {
        key: 'UnlimitedWords',
        isBool: true,
        unit: '',
        label: 'UnlimitedWords',
        note: 'Craft detailed job descriptions of up to 600 words, allowing you to provide potential applicants with a thorough understanding of the position and its requirements.'
    },
    1: {
        key: 'Display60Days',
        isBool: true,
        unit: '',
        label: 'Display60Days',
        note: 'Your job advert will be prominently displayed on our platform for up to 60 days, ensuring prolonged exposure and increasing the likelihood of attracting qualified candidates.'
    },
    2: {
        key: 'InOutSubmissions',
        isBool: true,
        unit: '',
        label: 'InOutSubmissions',
        note: 'You have the option to choose how candidates can apply for a position. For a seamless process, accept applications directly to your account with us or direct candidates to apply on your website.'
    },
    3: {
        key: 'Expiration12Months',
        isBool: true,
        unit: '',
        label: 'Expiration12Months',
        note: 'Post your job advert anytime within 365 days from the date of purchase, giving you flexibility and ample time to find the right match for your organisation (please refer to our Terms of Use).'
    },
    4: {
        key: 'TextAdvertDetailHeaderBanner',
        isBool: true,
        unit: '',
        label: 'TextAdvertDetailHeaderBanner',
        note: 'Incorporate a custom header image into the job description page to showcase your brand identity and values. This is your chance to leave a lasting impression on applicants.'
    },
    5: {
        key: 'ShowLogo',
        isBool: true,
        unit: '',
        label: 'ShowLogo',
        note: 'Your organisation’s logo will be prominently displayed alongside your job listings in search results, capturing the attention of potential candidates from the start.'
    },
    6: {
        key: 'EasyToFindFilterPremium',
        isBool: true,
        unit: '',
        label: 'EasyToFindFilterPremium',
        note: 'Your organisation’s logo will be showcased on the job description page, enhancing your brand visibility and credibility among potential applicants.'
    },
    7: {
        key: 'RibbonMark',
        isBool: true,
        unit: '',
        label: 'RibbonMark',
        note: 'Your job listing will stand out with a distinctive light blue background, capturing candidates’ attention instantly. This eye-catching design element ensures your listing gets noticed, making it more likely for potential candidates to explore the opportunities you offer.'
    },
    8: {
        key: 'JobAlertEmails',
        isBool: true,
        unit: '',
        label: 'JobAlertEmails',
        note: 'Your job posting will be sent to potential candidates who have registered for our job alert service. This expanded reach ensures your listings land in the inboxes of the most relevant talent, maximising your chances of finding the perfect match.'
    },
    9: {
        key: 'AllowVideoLink',
        isBool: true,
        unit: '',
        label: 'AllowVideoLink',
        note: 'With our Premium Advert feature, you can embed video content directly into the job description page using a video URL. This allows candidates to engage with dynamic visuals, giving them a deeper understanding of your organisation\'s unique culture and core values.For proper functionality, please ensure your video content is hosted and available on YouTube.'
    },
    10: {
        key: 'SpecialSearchResultSection',
        isBool: true,
        unit: '',
        label: 'SpecialSearchResultSection',
        note: 'Your job postings will enjoy prime positioning with purple frames, appearing before other types of adverts in the search results. This maximises the chances of grabbing attention and attracting the ideal candidates.'
    },
    11: {
        key: 'EasyToFindFilterFeatured',
        isBool: true,
        unit: '',
        label: 'EasyToFindFilterFeatured',
        note: 'We prioritise your job posting among our filtering criteria, ensuring it’s easily accessible and discoverable by candidates. By being prominently displayed, your listing becomes a go-to option for jobseekers, increasing the probability of receiving applications from qualified individuals.'
    },
    12: {
        key: 'JobAlertNotifications',
        isBool: true,
        unit: '',
        label: 'JobAlertNotifications',
        note: 'Stand out by being featured in our job alert notifications. Candidates who set up alerts matching your job criteria will receive notifications, increasing the chances of your job posting reaching the right people.'
    },
    13: {
        key: 'ShowLogoJobDetails',
        isBool: true,
        unit: '',
        label: 'ShowLogoJobDetails',
        note: 'Your organisation’s logo will be showcased on the job description page, enhancing your brand visibility and credibility among potential applicants.'
    },
    14: {
        key: 'DocumentsUpload',
        isBool: true,
        unit: '',
        label: 'DocumentsUpload',
        note: 'Easily attach relevant documents to your job listings, ensuring that candidates have access to all the essential information you want to share with them. Streamline the application process and provide comprehensive details effortlessly.'
    }
};

export const SUBFEATURES = {
    200: {
        key: 'CustomisedEmployerProfile',
        isBool: false,
        unit: 'Tab',
        label: 'CustomisedEmployerProfile',
        note: 'Organise your profile with tabs that categorise information, making it easy for candidates to navigate and find relevant details about your institution.'
    },
    201: {
        key: 'PhotosGallerySlider',
        isBool: false,
        unit: 'Photo',
        label: 'PhotosGallerySlider',
        note: 'Create a gallery showcasing photos of your institution, events, and workplace culture, providing candidates with a visual insight into what it\'s like to work with you.'
    },
    202: {
        key: 'KeyContactInformation',
        isBool: true,
        unit: '',
        label: 'KeyContactInformation',
        note: 'Provide essential contact information for your institution, including phone number, email address, and physical address, so potential candidates can easily reach out to you.'
    },
    203: {
        key: 'MapLocation',
        isBool: true,
        unit: '',
        label: 'MapLocation',
        note: 'Showcase your institution\'s location with an interactive map feature, helping candidates easily find your office and understand the surrounding area.'
    },
    204: {
        key: 'KeyInformationCards',
        isBool: true,
        unit: '',
        label: 'KeyInformationCards',
        note: 'Highlight important information about your institution, such as size metrics, employee benefits, job satisfaction, student statistics, diversity initiatives, and the overall working environment, providing candidates valuable insights into what sets you apart.'
    },
    205: {
        key: 'VideoUrlLink',
        isBool: true,
        unit: '',
        label: 'VideoUrlLink',
        note: 'Add engaging video content to your profile, such as institutional overviews or employee testimonials, by embedding a video URL. This provides a dynamic way to showcase your workplace and connect with potential candidates. For proper functionality, please ensure your video content is hosted and available on YouTube.'
    },
    206: {
        key: 'AdvertGoesLiveInHours',
        isBool: true,
        unit: '',
        label: 'AdvertGoesLiveInHours',
        note: 'You can post job and banner adverts and have them go live immediately by hitting the “Post Now” button.'
    },
    207: {
        key: 'FullControlOfJobAdverts',
        isBool: true,
        unit: '',
        label: 'FullControlOfJobAdverts',
        note: 'Manage and customise your job adverts with complete flexibility, allowing you to edit or remove postings at any time to align with your hiring needs.'
    },
    208: {
        key: 'AdvertsPerformanceMonitor',
        isBool: true,
        unit: '',
        label: 'AdvertsPerformanceMonitor',
        note: 'Track the performance of your job adverts with metrics on how candidates respond to them, helping you to make data-driven decisions.'
    },
    209: {
        key: 'CustomisedApplicationFormTemplates',
        isBool: false,
        unit: '',
        label: 'CustomisedApplicationFormTemplates',
        note: '209'
    },
    210: {
        key: 'NotificationsMessagingCenter',
        isBool: true,
        unit: '',
        label: 'NotificationsMessagingCenter',
        note: 'Communicate directly with candidates through a built-in messaging system, facilitating quick and efficient discussions without ever needing to leave the platform.'
    },
    211: {
        key: 'ApplicationTrackingBoard',
        isBool: false,
        unit: 'stage',
        label: 'ApplicationTrackingBoard',
        note: 'Utilise a visual tracking board to monitor the status of applications, helping you efficiently manage the hiring process and communicate updates with your team and candidates.<br/><br/><p style="text-align: center !important"><b>3 stages: </b><br/> Submitted – Unprocessed - Withdrawn</p><p style="text-align: center !important"><b>5 stages: </b><br/> Submitted – Unprocessed - Successful – Unsuccessful – Withdrawn</p><p style="text-align: center !important"><b>7 stages: </b><br/> Submitted – Unprocessed - Screening – Shortlisted - Successful - Unsuccessful - Withdrawn</p><p style="text-align: center !important"><b>9 stages: </b><br/> Submitted – Unprocessed - Screening – Shortlisted – Interviewing - Further Assessment - Successful - Unsuccessful - Withdrawn</p><p style="text-align: center !important"><b>12 stages</b><br/> Submitted – Unprocessed - Screening – Shortlisted – Interviewing - Further Assessment - Candidate of High Consideration – Reference and Background Check - Offering - Successful – Unsuccessful - Withdrawn</p>'
    },
    212: {
        key: 'ApplicationDashboard',
        isBool: true,
        unit: '',
        label: 'ApplicationDashboard',
        note: 'Access a centralised dashboard to monitor and manage all job applications, providing an overview of candidate submissions to streamline your hiring process.'
    },
    213: {
        key: 'NumberApplicationFormTemplates',
        isBool: false,
        unit: 'Template',
        label: 'NumberApplicationFormTemplates',
        note: 'Streamline the application process by using customisable templates that ensure consistency and efficiency when collecting candidate information.'
    },
    214: {
        key: 'SpotlightClassification',
        isBool: true,
        unit: '',
        label: 'SpotlightClassification',
        note: 'Gain increased visibility by being featured on the Spotlight Employer List, showcasing your institution as a great choice for potential candidates.'
    },
    215: {
        key: 'ProfileCover',
        isBool: true,
        unit: 'KG',
        label: 'ProfileCover',
        note: 'Personalise your institution\'s profile with a cover photo that represents your brand and culture, making a solid first impression on potential candidates.'
    },
    216: {
        key: 'InstitutionalProfile',
        isBool: true,
        unit: '',
        label: 'InstitutionalProfile',
        note: 'Enhance your institution\'s branding by uploading your logo, making your profile visually appealing and easily recognisable to prospective candidates.'
    },
    217: {
        key: 'SocialMediaLinks',
        isBool: true,
        unit: '',
        label: 'SocialMediaLinks',
        note: 'Integrate outbound links to your institution\'s social media profiles, allowing candidates to explore your online presence and engage with your content.'
    },
    218: {
        key: 'FindEmployerProfileVisibility',
        isBool: true,
        unit: '',
        label: 'FindEmployerProfileVisibility',
        note: 'Free subscribers will only have a visible profile when they have active job adverts on the platform. However, paid subscribers enjoy constant visibility, ensuring their profile is accessible to candidates at all times. As a welcome offer and for a limited time, all institution profiles will enjoy visibility, regardless of whether they have active adverts.'
    },
    219: {
        key: 'MultiUserAccessManagement',
        isBool: false,
        unit: 'User',
        label: 'MultiUserAccessManagement',
        note: '219'
    },
    220: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
    221: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
    222: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
    223: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
    224: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
    225: {
        key: '220',
        isBool: false,
        unit: '',
        label: '220',
    },
}

export function canAccess(permissionId, bool = true) {
    const lf = localStorage.getItem('features');
    if (!lf) return false;
    const features = JSON.parse(lf);
    if (!features) return false;
    var featuresObj = {};
    features.forEach(
        function (f) {
            featuresObj[f.Feature.Value + ''] = f.Value;
        }.bind(this)
    );
    if (bool)
        return (featuresObj && permissionId in featuresObj) ? true : false
    return (featuresObj && permissionId in featuresObj) ? featuresObj[permissionId] : 0
}
export function canAccessProduct(features, permissionId) {
    if (!features) return false;
    var featuresObj = {};
    features.forEach(
        function (f) {
            featuresObj[f.Feature.Value + ''] = true; //f.Value;
        }.bind(this)
    );
    return (featuresObj && permissionId in featuresObj) ?
        featuresObj[permissionId] : false
}