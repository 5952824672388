export const dividerOp = {
    avilableOptions: {
        width: [6, 12],
        linesCount: [1, 2, 3],
        linesL: ['', 'thin', 'double', 'bold'],
    },
    newDividerlinesCount: 1,
    newDividerWidth: 12,
    editMode: false,
    _index: 0,
    _idx: 0,
};
export const spacerOp = {
    avilableOptions: [1],
    newSpacer: 1,
    editMode: false,
    _index: 0,
    _idx: 0,
};
export const questionOp = {
    title: '',
    desc: '',
    helpText: '',
    placeholder: '',
    tooltip: '',
    width: 12,
    allowMultipleAnswers: false,
    isRequired: false,
    minLength: 0,
    maxLength: 500,
    formDataSources: [],
    inputFormat: 5,
    responseType: 0,
    editMode: false,
    consecidx: null,
    conidx: null,
    quesidx: null,
    container: false,

    _index: 0,
    _idx: 0,
};
export const containerOp = {
    title: '',
    description: '',
    widgetsArray: [],
    multiResponse: false,
    editMode: false,
    _index: 0,
    _idx: 0,
};
export const descriptionOp = {
    newTitle: '',
    newDesc: '',
    editMode: false,
    _index: 0,
    _idx: 0,
};

export const types = {
    SHORTTEXT: 0,
    LONGTEXT: 1,
    NUMBER: 2,
    DATE: 3,
    CHECKBOX: 4,
    DROPDOWN: 5,
    RADIOBOX: 6,
    UPLOADFILE: 7,
    CONTAINER: 8,
    SPACER: 9,
    DIVIDER: 10,
    DESCRIPTION: 12,
    ROW: 13,
    PERSONAL: 14
}

export const qTypes = [
    types.SHORTTEXT,
    types.LONGTEXT,
    types.NUMBER,
    types.DATE,
    types.CHECKBOX,
    types.DROPDOWN,
    types.RADIOBOX,
    types.UPLOADFILE];

export const ultimateIds = ['59a9d594-cefe-4314-74da-08da6a3cce41', '93b666d9-d517-47c5-f3f8-08d9e352ff71', 'fe0ba11d-eea6-42ac-74df-08da6a3cce41'];

export const qWithOptions = [
    types.CHECKBOX,
    types.DROPDOWN,
    types.RADIOBOX];

export const questionResponseTypes = [{
    id: 0,
    text: 'Short Text'
},
{
    id: 1,
    text: 'Long Text'
},
{
    id: 2,
    text: 'Number'
},
{
    id: 3,
    text: 'Date'
},
{
    id: 4,
    text: 'Checkbox'
},
{
    id: 5,
    text: 'Dropdown List'
},
{
    id: 6,
    text: 'Radiobox'
},
{
    id: 7,
    text: 'File Upload'
}
]

export const questionResponseTypesNoUpload = [{
    id: 0,
    text: 'Short Text'
},
{
    id: 1,
    text: 'Long Text'
},
{
    id: 2,
    text: 'Number'
},
{
    id: 3,
    text: 'Date'
},
{
    id: 4,
    text: 'Checkbox'
},
{
    id: 5,
    text: 'Dropdown List'
},
{
    id: 6,
    text: 'Radiobox'
},
]


export function getDateFormate(formatCode) {
    return parseInt(formatCode) == 5
        ? 'DD/MM/YYYY'
        : 'MM/YYYY'
}
export function getWidthClass(width) {
    return width == 25 ? 'w-25' :
        width == 50 ? 'w-50' :
            width == 75 ?
                'w-75' :
                'w-100'
}