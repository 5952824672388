import { GetReq, PostReq, PutReq, DeleteReq, remoteIdentifier, remoteIdentifierObj, logout, bannerClick, getBanners, sucmsg, checkSession, checkSessionMiddleware } from "~/utilities/network";
export default (context, inject) => {
    inject('GetReq', GetReq);
    inject('PostReq', PostReq);
    inject('PutReq', PutReq);
    inject('DeleteReq', DeleteReq);
    inject('remoteIdentifier', remoteIdentifier);
    inject('remoteIdentifierObj', remoteIdentifierObj);
    inject('logout', logout);
    inject('bannerClick', bannerClick);
    inject('getBanners', getBanners);
    inject('sucmsg', sucmsg);
    inject('checkSession', checkSession);
    inject('checkSessionMiddleware', checkSessionMiddleware);
}