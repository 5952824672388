//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
    Pass location (page name) to get the banner adverts in sidebar positions (left and right)
*/
import * as ptypes from '~/utilities/props-types';
export default {
    props: {
        location: ptypes.vSTRING('home'),
        left: ptypes.fBOOLEAN,
        right: ptypes.fBOOLEAN,
    },
    data: function () {
        return {
            ads: [],
        };
    },
    async mounted() {
        this.ads = await this.$getBanners(this.location, this.right ? 1 : 2);
    },
    watch: {
        async '$route.query.p'() {
            this.ads = await this.$getBanners(
                this.location,
                this.right ? 1 : 2
            );
        },
    },
};
