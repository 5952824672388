//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as ptypes from '~/utilities/props-types';
export default {
    props: {
        active: '',
        mid: null,
        midUrl: null,
        home: ptypes.vSTRING('/')
    }
};
