import { render, staticRenderFns } from "./SidebarBanners.vue?vue&type=template&id=31fe4e9f&"
import script from "./SidebarBanners.vue?vue&type=script&lang=js&"
export * from "./SidebarBanners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartImage: require('C:/Workspace/Edujobic/Gitlab Frontend/components/global/SmartImage.vue').default})
