//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SidebarBanners from '~/components/common/ads/SidebarBanners';
import BottomBanners from '~/components/common/ads/BottomBanners';
import TopBanners from '~/components/common/ads/TopBanners';
import HeaderSection from '~/components/common/layout/HeaderSection';
import FooterSection from '~/components/common/layout/FooterSection';
import MobileMenu from '~/components/common/layout/MobileMenu';
import LogoutToLogin from '~/components/auth/LogoutToLogin';

import {
    scrollTop,
    stickyHeaderHandler,
    resizeHandler,
    showScrollTopHandler
} from '~/utilities/browser.js';

export default {
    head() {
        return {
            link: [
                {
                    rel: 'stylesheet',
                    href: '/css/shared.css',
                    async: true,
                    defer: true
                },
                {
                    rel: 'stylesheet',
                    href:
                        '/css/layout/' +
                        (this.extrabase ? this.extrabase : 'higher') +
                        '.css',
                    async: true,
                    defer: true
                }
            ]
        };
    },

    components: {
        TopBanners,
        BottomBanners,
        SidebarBanners,
        HeaderSection,
        FooterSection,
        MobileMenu,
        LogoutToLogin
    },
    data: function() {
        return {
            posts: [],
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher'
        };
    },
    watch: {
        $route: function() {
            resizeHandler();
            this.$modal.hideAll();
            this.$extrabaseLogic();
            this.extrabase = localStorage.getItem('extrabase');
        }
    },
    mounted: function() {
        window.addEventListener('scroll', stickyHeaderHandler, {
            passive: true
        });
        window.addEventListener('scroll', showScrollTopHandler, {
            passive: true
        });
        window.addEventListener('resize', stickyHeaderHandler);
        window.addEventListener('resize', resizeHandler);
        this.$extrabaseLogic();
        this.extrabase = localStorage.getItem('extrabase');
    },
    computed: {
        breadcrumbTitle() {
            return this.$route.matched.map(r => {
                return r.components.default.options.breadcrumbTitle;
            })[0];
        },
        introMsg() {
            return this.$route.matched.map(r => {
                return r.components.default.options.introMsg;
            })[0];
        }
    },
    destroyed: function() {
        window.removeEventListener('scroll', showScrollTopHandler, {
            passive: true
        });
        window.removeEventListener('scroll', stickyHeaderHandler, {
            passive: true
        });
        window.removeEventListener('resize', stickyHeaderHandler);
        window.removeEventListener('resize', resizeHandler);
    },
    methods: {
        scrollToTop: function() {
            scrollTop(false);
        },
        hideMobileSearch: function() {
            if (document.querySelector('.header-search.header-search-inline')) {
                let headerSearch = document.querySelector(
                    '.header-search.header-search-inline'
                );
                headerSearch.classList.remove('show');
                headerSearch
                    .querySelector('.header-search-wrapper')
                    .classList.remove('show');
            }
            if (document.querySelector('.search-suggests')) {
                document
                    .querySelector('.search-suggests')
                    .setAttribute('style', 'display: none');
            }
        }
    }
};
