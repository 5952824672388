//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from 'vue-avatar-component';
import { v4 as uuidv4 } from 'uuid';
import * as ptypes from '~/utilities/props-types';
export default {
    props: {
        src: String,
        acsscls: String,
        width: Number,
        height: Number,
        aWidth: ptypes.nSTRING,
        ratio: Number,
        alt: ptypes.STRING,
    },
    data: function () {
        return {
            useAvatar: false,
            avatarSize: 0,
            H: 0,
            aHeight: '100%',
            id: uuidv4(),
            done: false,
            mode: 3, // 1: real accurate, 2: height from ratio x width, 3: static width and height, 4: width thresholds, 5: largest threshold
            avatarColors: [
                '917AB5',
                //'BFDCE3',
                //'D1CBE5',
                //'E4F2F5',
                //'D7CFE4',
                //'E8F2F5',
                //'B7BDDF',
            ],
            idx: 0,
            theSrc: '',
        };
    },
    components: { Avatar },
    mounted() {
        this.idx = 0; //this.randomIntFromInterval(0, 4);
        this.loadimg();
    },
    methods: {
        addNewLines(str) {
            var array = str.split(' ');
            var newstring = '';
            array.forEach(function (e, i) {
                newstring += e + ' ';
                if ((i + 1) % 2 == 0) {
                    newstring += '\\n';
                }
            });
            return newstring;
        },
        loadimg() {
            if (this.done) return;
            this.theSrc =
                this.src + '?width=' + this.width + '&height=' + this.height;

            const myImage = new Image();
            myImage.src = this.theSrc;
            myImage.onload = () => {
                this.done = true;
            };
            myImage.onerror = () => {
                this.done = true;
            };
        },
        goAvatar() {
            this.useAvatar = true;
            const el = document.getElementById(this.id);
            if (!el) return;
            const width = Math.round(el.getBoundingClientRect().width);
            const height = Math.round(width / this.ratio);
            this.aHeight = height + 'px';
        },
        randomIntFromInterval(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
    },
};
