import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyA4X6T11jj5JIOrNwIz6WY6hOgxrDb2jl8", // AIzaSyBcNmvuTCPkfGNLDwfN3w0RLLBiiAiA4cM
        libraries: 'places',
    },
    installComponents: true
});
//DEV:     AIzaSyD3exP8ZSLwAWKhZob2xIatYPurkjYPKAU
//STAGE: AIzaSyBcNmvuTCPkfGNLDwfN3w0RLLBiiAiA4cM